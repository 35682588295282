import globalValues from "./globalValues";

export const defaultApartment = {
  activeForRent: true,
  concurrentContracts: 1,
  vatRegister: false,
  country: "Finland",
  condominium: { loans: [] },
  forms: [],
  images: [],
  oldMaintenanceCharges: [],
  oldWaterCharges: [],
  currentLoans: [],
  oldLoans: [],
  owners: [],
  rooms: [],
  salesInfo: {},
  furnished: false,
  assignment: { inUse: false, realtors: [] },
};

export const defaultTenant = {
  vatRegister: false,
  creditReport: "",
  isOnlyKeyHolder: false,
  finnishCitizen: true,
  personalRefNumInUse: true,
  isCompany: false,
  isProspect: false,
  name: "",
  social: "",
  businessId: "",
  email: "",
  address: "",
  zipCode: "",
  city: "",
  country: "Finland",
  phone: "",
  comments: "",
  contactPersonName: "",
  contactPersonPhone: "",
  evoice: {
    evoiceOperator: "",
    evoiceId: "",
    evoiceAddress: "",
  },
  selectedTenantType: "tenant",
  invoiceLanguage: "fin",
  keys: [],
};

export const defaultCondominium = {
  country: "Finland",
  administrator: { waterEqualizationEmailReceivers: [] },
  loans: [],
  oldLoans: [],
};

export const defaultCondominiumLoanForm = {
  active: true,
  name: "",
  method: "",
  handling: "",
  refRateType: "",
  endDate: "",
  curRefRateIntStart: "",
  curRefRateInt: null,
  margin: null,
  morgageExemptionActive: false,
  morgageExemptionEnds: "",
};

export const defaultApartmentLoanForm = {
  amount: null,
  totalAmount: null,
  currentAmount: null,
  startDate: "",
  condominiumLoanId: null,
};

export const defaultRentalContract = {
  landlords: [
    {
      isCompany: null,
      name: "",
      social: "",
      businessId: "",
      phone: "",
      address: "",
      zipCode: "",
      city: "",
      email: "",
      bank: "",
      iban: "",
      onModel: "",
      landlordId: "",
    },
  ],
  tenant: {
    tenantId: "",
    tenantType: "first",
    isCompany: false,
    name: "",
    social: "",
    finnishCitizen: true,
    businessId: "",
    address: "",
    zipCode: "",
    city: "",
    email: "",
    phone: "",
    others: "",
    contactPersonName: "",
    contactPersonPhone: "",
  },
  otherTenants: [],
  apartment: {
    id: "",
    roomIds: [],
    condominium: {},
    address: "",
    apartmentNumber: "",
    zipCode: "",
    city: "",
    country: null,
    area: null,
    otherRooms: "",
    floorPlan: "",
    condition: true,
    photos: false,
    owners: [],
    activeForRent: true,
  },
  lease: {
    leaseType: "Toistaiseksi voimassa oleva",
    startDate: "",
    endDate: "",
    showDismissalDate: false,
    dismissalDate: "",
    moveOut: "",
    moveInDate: "",
    firstRentDate: "",
    rent: null,
    rentProduct: null,
    waterProduct: null,
    otherProducts: [],
    rentDueDate: "",
    rentDueDateNumber: null,
    otherCostsDetails: "",
    parkingCost: null,
    saunaCost: null,
    laundretteCost: null,
    otherCosts: null,
    waterChargeType: "usage",
    waterChargePerMonth: null,
    waterChargeCount: null,
    electricity: "",
    electricityAmount: null,
    useDeposit: true,
    deposit: null,
    depositType: "invoice",
    socialWelfareAccepted: false,
    depositDueDate: "",
    depositIban: "",
    absoluteGuarantee: {
      isUsed: false,
      name: "",
      social: "",
      maxAmount: null,
    },
    noRentIncrease: false,
    nextRentIncreaseDate: "",
    rentIncreaseTime: "",
    rentIncreaseType: "percentage",
    indexStartDate: "",
    indexStartValue: null,
    percentagePrefix: "enintään",
    rentIncreasePercentage: null,
    rentIncreaseFixed: null,
    rentIncreaseOwn: "",
    startRentPrice: null,
    rentIncreaseComment: "",
    compareToPreviousIndex: false,
  },
  others: {
    subLeaseAllowed: false,
    furnished: false,
    mandatoryInsurance: true,
    noPets: true,
    petsDetails: "",
    noSmoking: true,
    keys: null,
    keysLostSum: null,
    comments: "",
    signatureDate: "",
    signatureCity: "",
    eInvoice: true,
    signForOthers: false,
    keyHoldersFromContracts: [],
  },
  signaturePad: { signers: [], signature: "" },
  modelId: "",
  // wysiwyg
  photoComments: "",
  rentalProperty: "",
  condition: "",
  rentalTimeOpen: "",
  rentalTimeFixed: "",
  dismissalDateText: "",
  moveAHVL: "",
  moveEndDate: "",
  rent: "",
  deposit: "",
  modifications: "",
  otherData: "",
  signatureText: "",
};

export const defaultStats = {
  payments: {
    yearAvgPaymentDelay: null,
  },
  loans: {
    totalAmountThisMonth: 0,
    currentLoanAmount: 0,
    equityThisMonth: 0,
    updatedAt: null,
  },
  invoice: {
    cashFlowThisMonth: 0,
    rentIncomes: 0,
    updatedAt: null,
  },
  apartment: {
    maintenanceChargesThisMonth: 0,
    waterChargesThisMonth: 0,
    loanPaymentThisMonth: 0,
    housingCompanyLoans: 0,
    totalPurchasePrice: 0,
    totalEstimatedPrice: 0,
    totalEstimatedRent: 0,
    combinedPrice: 0,
    occupancyRates: { months2: 0, months12: 0 },
    updatedAt: null,
  },
  receipt: {
    receiptsThisMonth: 0,
  },
  outgoingPayments: { dates: { startDate: null, endDate: null }, updatedAt: null },
  updatedAt: null,
};

export const defaultGroup = {
  colors: globalValues.themeColors,
  fonts: { fontFamily: "Bai Jamjuree" },
  logo: null,
  texts: { footer: {} },
  showFooter: false,
  bypassGroupStyles: false,
};
