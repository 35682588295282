import moment from "moment";

export default {
  hasServiceId(invoice) {
    return invoice?.talenom?.invoiceId ? true : false;
  },

  hasRefundServiceId(invoice) {
    return invoice?.talenom?.refundToInvoiceId ? true : false;
  },

  getRefundServiceId(invoice) {
    return invoice?.talenom?.refundToInvoiceId || null;
  },

  contactHasServiceId(principalTenant) {
    return principalTenant?.tenantId?.talenom?.contactId ? true : false;
  },

  canDeleteInvoice(invoice) {
    if (invoice?.talenom?.invoiceId) {
      return !invoice.talenom.status || invoice.talenom.status !== "sent";
    } else {
      return true;
    }
  },

  canDownloadPreviewInvoice() {
    return false;
  },

  getInvoiceState() {
    return "";
  },

  invoiceIsDeptCollected(invoice) {
    const state = this.getInvoiceState(invoice);
    if (state == "Muistutus" || state == "Perintä") return false;
    else return true;
  },

  showChangeDueDateBtn() {
    return true;
  },

  getDueDateWarningText() {
    return `Lasku on edennyt muistutus- tai perintätilaan. Et voi enää muuttaa eräpäivää Vuokranetin kautta.
    Ota yhteys ylläpitoon.`;
  },

  isDateAvailableForDueDateChange(date, billDate, dueDate) {
    const today = moment().format("YYYY-MM-DD");
    const currentDueDate = moment(moment(dueDate).format("YYYY-MM-DD")).format("YYYY-MM-DD");
    const billDatePlus7Days = moment(moment(billDate).format("YYYY-MM-DD"))
      .add(7, "days")
      .format("YYYY-MM-DD");

    if (
      moment(date).isSameOrBefore(today) ||
      moment(date).isBefore(billDatePlus7Days) ||
      moment(date).isSame(currentDueDate)
    )
      return false;
    else return true;
  },

  canChangeDueDate(invoice) {
    const today = moment().format("YYYY-MM-DD");
    const dueDatePlusReminderTime = moment(invoice.dueDate).add(7, "days").format("YYYY-MM-DD");
    if (moment(today).isAfter(dueDatePlusReminderTime)) return false;
    return true;
  },

  getServiceInvoiceLanguages(globalLanguages) {
    const invoiceLanguages = ["fin", "eng", "swe", "ger"];
    return globalLanguages.filter((el) => invoiceLanguages.includes(el.val));
  },

  canUpdateTenantDataToService(tenant) {
    if (tenant?.talenom?.contactId) return true;
  },

  isInvoiceAddressCountryAvailable(country, availableCountries) {
    const available = availableCountries.talenom;
    return available.includes(country) || `Valitse: ${available}`;
  },

  canUpdateInvoiceStatuses() {
    return true;
  },

  getSingleInvoiceUpdateData(invoice) {
    return { talenomInvoiceId: invoice.talenom.invoiceId, invoiceId: invoice._id };
  },

  getInvoiceSearchLabelByService(invoiceType) {
    let text;
    switch (invoiceType) {
      case "invoice":
        text = "Laskunro, talenomId, saaja, saatavat, vuokrakohde, kohteen numero...";
        break;
      case "refundInvoice":
        text = "Laskunro, vuokralainen, hyvitys, vuokrakohde, kohteen numero...";
        break;
    }
    return text;
  },

  // canUsePersonalRefNum(invoice, principalTenant) {
  //   if (!invoice.isRecurrent) return true;
  //   // If recurrent invoice and personalRefnum is NOT in use, do not pass
  //   if (invoice.isRecurrent && !principalTenant.tenantId.personalRefNumInUse) return false;
  //   else return true;
  // },
};
