import formSubmissionService from "../../services/formSubmission.service";
import { showPopup } from "../../utils/helpers";

const state = {
  formSubmissions: [],
  images: [],
  totalLength: 0,
  loading: false,
  selectedFormSubmission: null,
};

const mutations = {
  setSubmissions(state, data) {
    state.formSubmissions = data.formSubmissions;
    state.totalLength = data.totalLength;
  },

  setSelectedFormSubmission(state, item) {
    state.selectedFormSubmission = { ...item };
  },

  setFormSubmissionImages(state, document) {
    if (document) {
      state.images = document.images;
    } else {
      state.images = [];
    }
  },

  setLoading(state, val) {
    state.loading = val;
  },

  setCurrentSubmission(state, form) {
    // set label values to oldLabel
    form.rows = form.rows.map((row) => {
      row.cols = row.cols.map((col) => {
        // Update old label and oldType
        col.oldLabel = col.label;
        col.oldFieldType = col.fieldType;
        return col;
      });
      return row;
    });

    state.currentForm = { ...form };
    state.loading = false;
  },

  removeSubmission(state, id) {
    state.formSubmissions = state.formSubmissions.filter((el) => id != el._id);
    state.totalLength--;
  },
};

const actions = {
  async getFormSubmissionsByFormId({ commit }, url) {
    try {
      const res = await formSubmissionService.getFormSubmissionsByFormId(url);
      commit("setSubmissions", res.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getOne({ commit }, id) {
    try {
      const res = await formSubmissionService.getOne(id);
      commit("setCurrentForm", res.data.form);
    } catch (err) {
      showPopup("Jokin meni pieleen lomakkeen lataamisessa.", "error");
    }
  },

  async getFormSubmissionImages({ commit }, id) {
    try {
      const res = await formSubmissionService.getFormSubmissionImages(id);
      commit("setFormSubmissionImages", res.data.document);
    } catch (err) {
      throw new Error(err);
    }
  },

  async deleteSubmission({ commit }, data) {
    try {
      await formSubmissionService.deleteSubmission(data);
      commit("removeSubmission", data.id);
      showPopup("Yhteydenotto poistettu", "success");
    } catch (err) {
      showPopup(err, "error");
    }
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
