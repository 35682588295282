import { snackbarBus } from "../main";
import moment from "moment";
import router from "../router/router";
import store from "../store/store";
import globalValues from "../configs/globalValues";
import { translateWords } from "../language/translator";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import _ from "lodash";
import defaultImagePath from "../../public/images/logo/vuokranet _color.png";
import Excel from "exceljs";

export default {
  computed: {
    ...mapState("userProfile", ["resourcePermissions", "fieldPermissions"]),
    ...mapGetters("account", ["defaultTableRows"]),
    ...mapGetters(["getIsGroupMode"]),
    ...mapGetters("account", ["getAccountColors", "getCustomLogo"]),
  },

  methods: {
    ...mapMutations("browsehistory", ["returnPreviousPage"]),
    ...mapActions("account", ["switchAccount"]),

    // AUTH
    getAccountPermissions() {
      return store.getters.getAccountPermissions;
    },

    isGroupMode() {
      return this.getIsGroupMode;
    },

    isAuthorized(resource, permission) {
      if (resource && permission && this.resourcePermissions) {
        const found =
          resource in this.resourcePermissions && permission in this.resourcePermissions[resource];
        return found ? this.resourcePermissions[resource][permission] : false;
      }
      return false;
    },

    isDisabled(resource, field) {
      if (!resource || !field) return false;
      if (resource in this.fieldPermissions) {
        return !_.get(this.fieldPermissions[resource], field, true);
      }

      return false;
    },

    formatPermissionTabs(tabs) {
      let accountPermissions = this.getAccountPermissions();

      let newTabs = tabs.map((tab) => {
        if (tab.access) {
          if (accountPermissions[tab.access] == undefined) {
            return tab;
          } else {
            return accountPermissions[tab.access]
              ? tab
              : {
                  text: `${tab.text} (pro)`,
                  access: tab.access,
                  isPremium: true,
                };
          }
        } else {
          return tab;
        }
      });
      return newTabs;
    },

    async switchToAccount(createdBy, redirectUrl) {
      try {
        await this.switchAccount(createdBy._id);
        router.push(redirectUrl);
        this.showPopup(`Tiliksi vaihdettiin ${createdBy.name}`, "success");

        setTimeout(() => {
          router.go();
        }, 300);
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    // GENERAL

    getDefaultTableRows() {
      return this.defaultTableRows;
    },

    getSendIcon(type) {
      switch (type) {
        case "email":
          return "mdi-at";
        case "print":
          return "mdi-printer";
        case "evoice":
          return "mdi-cloud-print-outline";
        case "post":
          return "mdi-email-outline";
      }
    },

    formatSendType(type) {
      switch (type) {
        case "email":
          return "Email";
        case "print":
          return "Itse toimitettava";
        case "evoice":
          return "Verkkolasku";
        case "post":
          return "Kirje";
      }
    },

    getModelType(type) {
      switch (type) {
        case "rentalContract":
          return "Vuokrasopimus";
        case "otherContract":
          return "Muu sopimus";
        case "rentIncrease":
          return "Vuokrankorotus";
      }
    },

    formatRentIncreaseMessageStatus(type) {
      if (!type) {
        return null;
      }

      switch (type) {
        case "pending":
          return "Odottaa lähetystä";
        case "delivered":
          return "Toimitettu vastaanottajalle";
        case "opened":
          return "Viesti avattu";
        case "temporaryFail":
          return "Virhe lähetyksessä, yritetään uudelleen";
        case "permanentFail":
          return "Viestiä ei voitu toimittaa";
      }
    },

    getRentIncreaseMessageColor(type) {
      switch (type) {
        case "pending":
          return "";
        case "delivered":
          return "info--text";
        case "opened":
          return "success--text";
        case "temporaryFail":
          return "warning--text";
        case "permanentFail":
          return "error--text";
      }
    },

    stayOnSamePageAndTab() {
      this.returnPreviousPage();
    },

    showPopup: (text, color) => {
      snackbarBus.$emit("createsnackbar", {
        text: text,
        color: color,
      });
    },

    goBack() {
      router.go(-1);
    },

    scrollToTop() {
      const doc = document.getElementById("app");
      if (doc) doc.scrollIntoView({ behavior: "smooth" });
    },

    hasContractEmails(contract, person) {
      const emails = this.getContractEmails(contract, person);
      return emails.length > 0 ? true : false;
    },

    sendMail(contract, person) {
      const emails = this.getContractEmails(contract, person);
      this.mailTo(emails);
    },

    mailTo(emailsArr) {
      let string = "";
      for (let i = 0; i < emailsArr.length; i++) {
        if (i == emailsArr.length - 1) {
          string += emailsArr[i];
        } else {
          string += `${emailsArr[i]},`;
        }
      }
      window.location.href = `mailto:${string}`;
    },

    getContractEmails(contract, person) {
      // Tenant
      let emails = [];

      if (person == "tenant") {
        if (contract?.tenant?.tenantId?.email) {
          emails = [contract.tenant.tenantId.email];
        }
        contract.otherTenants.forEach((el) => {
          if (el?.tenantId?.email) emails.push(el.tenantId.email);
        });
      } else if (person == "partner") {
        // Partner
        contract.landlords.forEach((el) => {
          if (el.onModel == "Partner" && el?.landlordId?.email) {
            emails.push(el.landlordId.email);
          }
        });
      }

      return emails;
    },

    // VALIDATE

    hasItems(items) {
      if (!items) return false;
      return items.length > 0 ? true : false;
    },

    // GETTERS

    getImageCloudFrontUrl(img) {
      const timestamp = new Date().getTime();
      return `https://${process.env.VUE_APP_CLOUDFRONT_URL}/${img.key}?t=${timestamp}`;
    },

    getLogoCloudFrontUrl(img) {
      const timestamp = new Date().getTime();
      if (img)
        return `https://${process.env.VUE_APP_CLOUDFRONT_LOGO_URL}/${img.key}?t=${timestamp}`;
      else return "";
    },

    getFormImageCloudFrontUrl(img) {
      const timestamp = new Date().getTime();
      return `https://${process.env.VUE_APP_CLOUDFRONT_FORM_URL}/${img.key}?t=${timestamp}`;
    },

    getContractState(state) {
      let text;
      switch (state) {
        case "Ei allekirjoitettu":
          text = "Ei aktivoitu";
          break;

        case "Voimassa oleva":
          text = "Voimassa oleva";
          break;

        case "Irtisanottu":
          text = "Irtisanottu";
          break;

        case "Päättynyt":
          text = "Päättynyt";
          break;
      }
      return text;
    },

    getRentIncreaseMethod(type) {
      switch (type) {
        case "percentage":
          return "Prosenttikorotus";
        case "index":
          return "Elinkustannusindeksi";
        case "indexPlusPercentage":
          return "Elinkustannusindeksi ja prosenttikorotus";
        case "consumerIndex":
          return "Kuluttajaindeksi";
        case "consumerIndexPlusPercentage":
          return "Kuluttajaindeksin pisteluku ja prosenttikorotus";
        case "propertyMaintenanceIndex":
          return "Kiinteistön ylläpidon kustannusindeksi";
        case "propertyMaintenancePlusPercentageIndex":
          return "Kiinteistön ylläpidon kustannusindeksi ja prosenttikorotus";
        case "fixed":
          return "Kiinteä summa";
        case "own":
          return "Itse määritetty";
      }
    },

    getContractStateColor(state) {
      let color;
      switch (state) {
        case "Ei allekirjoitettu":
          color = "draft--text";
          break;

        case "Voimassa oleva":
          color = "success--text";
          break;

        case "Irtisanottu":
          color = "warning--text";
          break;

        case "Päättynyt":
          color = "error--text";
          break;
      }
      return color;
    },

    getReservationState(state) {
      let string;
      switch (state) {
        case "pending":
          string = "Odottaa";
          break;

        case "completed":
          string = "Voimassa oleva";
          break;

        case "canceled":
          string = "Peruutettu";
          break;
      }
      return string;
    },

    getReservationStateColor(state) {
      let color;
      switch (state) {
        case "pending":
          color = "warning--text";
          break;

        case "completed":
          color = "success--text";
          break;

        case "canceled":
          color = "error--text";
          break;
      }
      return color;
    },

    getSignatureColor(item) {
      let color = "";
      // Signhero
      if (item.signatureMethod == "signhero") {
        color =
          item.signature.status == "notSend"
            ? ""
            : item.signature.status == "pending"
            ? "warning"
            : item.signature.status == "canceled"
            ? "error"
            : "success";
        // SignaturePad
      } else if (item.signatureMethod == "signaturePad") {
        color = item.signaturePad.status == "notSigned" ? "" : "success";
      } else if (item.signatureMethod == "vismaSign") {
        color =
          item.vismaSign.status == "new"
            ? "warning"
            : item.vismaSign.status == "pending"
            ? "warning"
            : item.vismaSign.status == "cancelled"
            ? "error"
            : "success";
      }

      return color;
    },

    getSignatureState(item) {
      let state = "Ei allekirjoitettu";
      // Signhero
      if (item.signatureMethod == "signhero") {
        state =
          item.signature.status == "notSend"
            ? "Ei lähetetty allekirjoitettavaksi"
            : item.signature.status == "pending"
            ? "Odottaa allekirjoituksia"
            : item.signature.status == "canceled"
            ? "Peruttu"
            : "Allekirjoitettu";
        // SignaturePad
      } else if (item.signatureMethod == "signaturePad") {
        state = item.signaturePad.status == "notSigned" ? "Ei allekirjoitettu" : "Allekirjoitettu";
      } else if (item.signatureMethod == "vismaSign") {
        state = !item.vismaSign.status
          ? "Ei lähetetty allekirjoitettavaksi"
          : item.vismaSign.status == "new"
          ? "Lähetetään allekirjoitettavaksi"
          : item.vismaSign.status == "pending"
          ? "Odottaa allekirjoituksia"
          : item.vismaSign.status == "cancelled"
          ? "Peruttu"
          : "Allekirjoitettu";
      }

      return state;
    },

    getReceiptType(type) {
      let str;
      switch (type) {
        case "receipt":
          str = "Muu kulu";
          break;

        case "maintenance":
          str = "Vastike";
          break;
      }
      return str;
    },

    getCountries() {
      return Object.keys(globalValues.countries);
    },

    getRentedRooms(apartment) {
      const rooms = apartment.id?.rooms || [];
      const roomIds = apartment?.roomIds || [];
      if (rooms.length === 0 || roomIds.length === 0) return [];
      let rentedRooms = [];

      roomIds.forEach((roomId) => {
        const index = rooms.findIndex((room) => String(room._id) === String(roomId));
        if (index != -1) rentedRooms.push(rooms[index]);
      });

      return rentedRooms;
    },

    // FORMATTING

    flattenObject(obj, path = "") {
      let result = {};

      for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
          const newPath = path ? `${path}.${key}` : key;

          if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
            result = { ...result, ...this.flattenObject(obj[key], newPath) };
          } else {
            result[newPath] = obj[key];
          }
        }
      }

      return result;
    },

    truncateString(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + "...";
    },

    formatPrice(price, decimals = 2) {
      if (typeof price === "number") {
        return price.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " €";
      } else return " - €";
    },

    formatCurrency(number, min = 2, max = 2) {
      if (typeof number === "number") {
        return new Intl.NumberFormat("fi-FI", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: min,
          maximumFractionDigits: max,
        }).format(number);
      } else return " - €";
    },

    formatMonthDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth() + 1,
        0
      ).getDate()}.${month}.${year}`;
    },

    formatMonthFirstDate(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `01.${month}.${year}`;
    },

    formatDate(date) {
      if (date) return moment(date).format("DD.MM.YYYY");
      else return "";
    },

    formatDateAndTime(date) {
      if (date) {
        const day = moment(date).format("DD.MM.YYYY");
        const time = new Date(date).toLocaleString("fi-FI", {
          hour: "2-digit",
          minute: "2-digit",
        });
        return `${day}, ${time}`;
      } else {
        return "";
      }
    },

    formatDateMonthName(date) {
      if (date) {
        const month = new Date(date).getMonth();
        const year = new Date(date).getFullYear();
        return `${globalValues.months[month]} ${year}`;
      } else {
        return "";
      }
    },

    formatQuarterName(date) {
      if (date) {
        const year = new Date(date).getFullYear();
        const month = moment(date).format("MM");
        const Q1 = ["01", "02", "03"];
        const Q2 = ["04", "05", "06"];
        const Q3 = ["07", "08", "09"];
        const Q4 = ["10", "11", "12"];

        if (Q1.includes(month)) {
          return `${year}Q1`;
        } else if (Q2.includes(month)) {
          return `${year}Q2`;
        } else if (Q3.includes(month)) {
          return `${year}Q3`;
        } else if (Q4.includes(month)) {
          return `${year}Q4`;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    formatIsoDate(date) {
      if (this.isIsoDate(date)) {
        return moment(date).format("DD.MM.YYYY");
      } else if (date) {
        return date;
      } else {
        return "";
      }
    },

    isIsoDate(str) {
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
      var d = new Date(str);
      return d.toISOString() === str;
    },

    formatBoolean(boolean) {
      if (typeof boolean === "undefined") {
        return "";
      } else {
        return boolean == false ? "Ei" : "Kyllä";
      }
    },

    formatDeposit(type) {
      if (type == "bank") return "Pankin vuokravakuustili";
      else if (type == "payment") return "Maksettu vuokranantajalle";
      else if (type == "guarantee") return "Henkilötakaus";
      else if (type == "commitment") return "Sosiaalitoimen maksusitoumus";
      else if (type == "customerReserveAccount") return "Asiakasvaratili";
      else return "Tuntematon";
    },

    // Replace product tags
    replaceProductTags(productDesc, dueDate, accountDate, lang) {
      const tags = [
        {
          text: "{{month_duedate}}",
          val: translateWords(globalValues.months[new Date(dueDate).getMonth()], lang, "months"),
        },
        {
          text: "{{month_accountdate}}",
          val: translateWords(
            globalValues.months[new Date(accountDate).getMonth()],
            lang,
            "months"
          ),
        },
      ];

      tags.forEach((el) => {
        let reg = new RegExp(el.text, "g");
        productDesc = productDesc.replace(reg, el.val);
      });

      return productDesc;
    },

    calcLastDayOfMonthDate(year, month) {
      return new Date(year, month + 1, 0).getDate();
    },

    // DOWNLOADS

    objectToCsv(data) {
      const csvRows = [];

      // get the headers
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      // loop over the rows and escape
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = (`` + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });

        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },

    // Create excel from data

    async createExcelBuffer(
      data,
      additionalInfo = [],
      addInfo = false,
      boldLastRow = false,
      setLogo = true,
      sumColumns = []
    ) {
      // Create a new workbook
      const wb = new Excel.Workbook();
      // Add a worksheet
      const ws = wb.addWorksheet("Vuokranet");
      // Get headers
      const headers = Object.keys(data[0]);
      // Get color for header
      let primaryColor = this.getAccountColors.primary;
      if (primaryColor) primaryColor = primaryColor.slice(1);
      else "#c3c3c3";
      // Decide color of header
      const isDark = this.isColorDark(primaryColor);
      const headerFontColor = isDark ? "FFFFFF" : "000000";
      const padding = 1.5;
      const headerHeightDefault = 35;
      const cellHeightDefault = 30;

      // ADD ADDITIONAL INFORMATION IF WANTED
      if (addInfo) {
        // Calculate the starting and ending columns dynamically based on the data
        const startingColumn = headers.length;

        additionalInfo.forEach((info, index) => {
          const cell = ws.getCell(index + 1, startingColumn);

          if (index === 0) {
            // title of report
            cell.value = info;
            cell.font = { bold: true, size: 13 };
            cell.alignment = { horizontal: "right" };
          } else {
            // Other rows of info
            cell.value = info;
            cell.font = { size: 12 };
            cell.alignment = { horizontal: "right" };
          }
        });
      } else {
        additionalInfo = [];
      }

      // Additional info must be atleast 3 rows hight because of logo if there is a logo
      if (setLogo && additionalInfo.length < 4) {
        const length = additionalInfo.length;
        additionalInfo.push(...Array(4 - length).fill(""));
      }

      // CALCULATE HEADER HEIGHTS AND MAX WIDTHS
      let maxHeaderHeight = 0;
      const maxHeaderWidths = Array(headers.length).fill(10); // min width is 10 units

      // Calculate and set the height and width of the header row
      headers.forEach((header, colIndex) => {
        const headerLines = header.split("\n");
        const headerLineCount = headerLines.length;
        const headerHeight = headerLineCount * headerHeightDefault;
        maxHeaderHeight = Math.max(maxHeaderHeight, headerHeight);

        // Calculate the maximum header width for the column
        headerLines.forEach((line) => {
          maxHeaderWidths[colIndex] = Math.max(maxHeaderWidths[colIndex], line.length + padding);
        });
      });

      // SET HEIGHT OF HEADER
      ws.getRow(additionalInfo.length + 1).height = maxHeaderHeight;

      // STYLE HEADERS
      headers.forEach((header, colIndex) => {
        const cell = ws.getCell(additionalInfo.length + 1, colIndex + 1);

        // Style cells
        cell.value = header;
        cell.font = { bold: true, color: { argb: headerFontColor }, size: 11 };
        cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: primaryColor } };
        cell.border = {
          top: { style: "thin", color: { argb: "DDDDDD" } },
          right: { style: "thin", color: { argb: "DDDDDD" } },
          bottom: { style: "thin", color: { argb: "DDDDDD" } },
          left: { style: "thin", color: { argb: "DDDDDD" } },
        };
        cell.alignment = { horizontal: "right", vertical: "middle", wrapText: true };
      });

      // SET LOGO
      if (setLogo) {
        const logo = this.getCustomLogo;

        if (logo) {
          const dimensions = logo.dimensions;
          const imageType = logo.mimeType.split("/")[1];
          const base64String = logo.buffer;
          const myBase64Image = `data:image/${imageType};base64,${base64String}`;
          const image = wb.addImage({
            base64: myBase64Image,
            extension: imageType,
          });

          // Adjust the image height if it exceeds the maximum width
          let maxImageWidth = 200;
          let ratio = dimensions.width / dimensions.height;
          let imageHeight = 65;
          let imageWidth = ratio * imageHeight;

          if (imageWidth > maxImageWidth) {
            imageHeight = (imageHeight * maxImageWidth) / imageWidth;
            imageWidth = ratio * imageHeight;
          } else {
            imageWidth = ratio * imageHeight;
          }

          ws.addImage(image, {
            tl: { col: 0.5, row: 0.65 },
            ext: { width: imageWidth, height: imageHeight },
            editAs: "absolute",
          });
        } else {
          // Fetch the image as a Blob
          const response = await fetch(defaultImagePath);
          const blob = await response.blob();

          // Convert the Blob to a base64 string
          const base64String = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });

          // Add the image to the workbook using the base64 string
          const image = wb.addImage({
            base64: base64String,
            extension: "png",
          });

          // Add the image to the worksheet
          ws.addImage(image, {
            tl: { col: 0.5, row: 0.78 },
            ext: { width: 203.08, height: 60 },
            editAs: "absolute",
          });
        }
      }

      // ADD DATA TO CELLS
      data.forEach((row, rowIndex) => {
        let maxRowHeight = 0;

        headers.forEach((header, colIndex) => {
          const cell = ws.getCell(additionalInfo.length + rowIndex + 2, colIndex + 1);

          // Check the type of data in the cell
          const cellValue = row[header];
          const isNumber = typeof cellValue === "number";
          // Set cell value and alignment
          cell.value = cellValue;
          cell.alignment = { horizontal: "right", vertical: "middle", wrapText: true };

          if (!isNumber) {
            // Handle multiline content and adjust row height
            const contentLineCount = String(row[header]).split("\n").length;

            // Calculate the row height based on the number of lines
            const rowHeight = contentLineCount * cellHeightDefault;
            maxRowHeight = Math.max(maxRowHeight, rowHeight);
          }
        });

        // Set the row height
        ws.getRow(additionalInfo.length + rowIndex + 2).height = maxRowHeight;
      });

      // CALCULATE TOTAL OF COLUMNS
      if (sumColumns.length > 0) {
        const totalRow = additionalInfo.length + data.length + 2;

        sumColumns.forEach((columnName) => {
          const colIndex = headers.indexOf(columnName);
          let totalValue = data.reduce((sum, row) => sum + this.getValueOfCell(row[columnName]), 0);
          const cell = ws.getCell(totalRow, colIndex + 1);
          cell.value = parseFloat(totalValue.toFixed(2));
        });
      }

      // CALCULATE COLUMN WIDTHS
      this.calculateColumnWidths(ws, headers, data, sumColumns, maxHeaderWidths, padding);

      // STYLE LAST ROW IF WANTED
      if (boldLastRow) {
        const lastRow = additionalInfo.length + data.length + (sumColumns.length > 0 ? 2 : 1);

        headers.forEach((header, colIndex) => {
          const cell = ws.getCell(lastRow, colIndex + 1);
          cell.font = { bold: true, size: 12 };
          // Align text to the right and center
          cell.alignment = { horizontal: "right", vertical: "middle" };
        });

        // Set height of row
        ws.getRow(lastRow).height = headerHeightDefault;
      }

      // CREATE BUFFER
      return await wb.xlsx.writeBuffer();
    },

    // Function to calculate column widths
    calculateColumnWidths(ws, headers, data, sumColumns, maxHeaderWidths, padding) {
      headers.forEach((header, colIndex) => {
        // Calculate the maximum content width for the column
        const maxColumnWidth =
          Math.max(
            maxHeaderWidths[colIndex], // Set width based on the maximum width of the header
            ...data.map((row) => {
              const cellContentLines = String(row[header]).split("\n");
              const maxLineLength = Math.max(...cellContentLines.map((line) => line.length));
              return maxLineLength;
            }),
            // Consider the width of the total column values
            ...sumColumns.map((columnName) => {
              const totalValue = data.reduce(
                (sum, row) => sum + this.getValueOfCell(row[columnName]),
                0
              );
              const totalLength = String(parseFloat(totalValue.toFixed(2))).length;
              return totalLength;
            })
          ) + padding;

        // Set the column width based on the maximum content width
        ws.getColumn(colIndex + 1).width = maxColumnWidth;
      });
    },

    getValueOfCell(val) {
      return typeof val === "number" ? val : 0;
    },

    isColorDark(hexColor) {
      if (!hexColor.startsWith("#")) hexColor = `#${hexColor}`;
      // Convert hex to RGB
      const r = parseInt(hexColor.substring(1, 3), 16);
      const g = parseInt(hexColor.substring(3, 5), 16);
      const b = parseInt(hexColor.substring(5, 7), 16);

      // Calculate perceived luminance (Y)
      const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

      // Check if the color is dark
      return luminance < 128;
    },

    downloadCsv(data, name, start, end) {
      var blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      if (start && end) a.setAttribute("download", `${start}-${end}_${name}.csv`);
      else a.setAttribute("download", `${name}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    downloadFile(buffer, name) {
      let blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", name);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },

    downloadExcel(buffer, name, start, end) {
      let blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      if (start && end) a.setAttribute("download", `${start}-${end}_${name}.xlsx`);
      else a.setAttribute("download", `${name}.xlsx`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },

    // SORTING

    mergeSort(unsortedArray, field) {
      // No need to sort the array if the array only has one element or empty

      if (unsortedArray.length <= 1) {
        return unsortedArray;
      }
      // In order to divide the array in half, we need to figure out the middle
      const middle = Math.floor(unsortedArray.length / 2);

      // This is where we will be dividing the array into left and right
      const left = unsortedArray.slice(0, middle);
      const right = unsortedArray.slice(middle);

      // Using recursion to combine the left and right
      return this.merge(this.mergeSort(left, field), this.mergeSort(right, field), field);
    },

    merge(left, right, field) {
      let resultArray = [],
        leftIndex = 0,
        rightIndex = 0;

      // We will concatenate values into the resultArray in order
      while (leftIndex < left.length && rightIndex < right.length) {
        if (left[leftIndex][field] < right[rightIndex][field]) {
          resultArray.push(left[leftIndex]);
          leftIndex++; // move left array cursor
        } else {
          resultArray.push(right[rightIndex]);
          rightIndex++; // move right array cursor
        }
      }

      // We need to concat here because there will be one element remaining
      // from either left OR the right
      return resultArray.concat(left.slice(leftIndex)).concat(right.slice(rightIndex));
    },
  },
};
