import { showPopup } from "../../utils/helpers";
import statisticService from "../../services/statistic.service";
import { defaultStats } from "../../configs/defaultState";
import _ from "lodash";

const state = {
  monthlyInvoiceData: [],
  charges: {},
  accountStatisticsLoading: true,
  monthlyStatusIsLoading: true,
  basicStatisticIsLoading: true,
  invoiceStatisticIsLoading: true,
  stats: _.cloneDeep(defaultStats),
};

const mutations = {
  getMonthlyInvoicing(state, data) {
    state.monthlyInvoiceData = data;
  },

  setCharges(state, data) {
    state.charges = data;
  },

  setStats(state, data) {
    if (data) {
      state.stats = data;
    } else {
      state.stats = _.cloneDeep(defaultStats);
    }
  },
};

const actions = {
  async getMonthlyInvoicing({ commit }) {
    try {
      const res = await statisticService.getMonthlyInvoicing();
      commit("getMonthlyInvoicing", res.data.monthlyInvoicing);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getMonthlyExpenses({ commit }) {
    try {
      const res = await statisticService.getMonthlyExpenses();

      commit("setCharges", res.data);
    } catch (err) {
      showPopup(err, "error");
    }
  },

  async getStats({ commit }) {
    try {
      const res = await statisticService.getStats();
      commit("setStats", res.data.statistics);
    } catch (err) {
      throw new Error(err);
    }
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
